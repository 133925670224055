<template>
  <div class="form-group form-row" :key="name">
    <CCol tag="label" sm="3" class="col-form-label">
      {{ label }}
    </CCol>
    <CCol sm="9">
      <font-picker :api-key="internalGoogleFontApiKey" :options="options" :active-font="computedValue" @change="$emit('input', $event.family)"/>
      <h4 class="apply-font">the quick brown fox jumps over the lazy dog</h4>
      <small
              v-if="description"
              class="form-text text-muted w-100"
              v-text="description"
      />
    </CCol>
  </div>
</template>

<script>
import FontPicker from 'font-picker-vue';
export default {
  name: 'PrtFontPicker',
  components: {
    FontPicker
  },
  props: {
    label: String,
    description: String,
    name: String,
    value: String
  },
  computed: {
    computedValue () {
      return this.value || 'Open Sans'
    }
  },
  data () {
    return {
      internalGoogleFontApiKey: process.env.VUE_APP_GOOGLE_FONT_API_KEY,
      options: {}
    }
  }
}
</script>

<style scoped>
  .apply-font {
    margin-top: 8px;
  }
</style>