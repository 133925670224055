<template>
  <CFormGroup
          v-bind="{append, prepend, validFeedback, invalidFeedback,
        tooltipFeedback, description, wrapperClasses, class: computedClasses}"
  >
    <template #label>
      <slot name="label">
        <label v-if="label" :for="safeId" :class="labelClasses">
          {{label}}
        </label>
      </slot>
    </template>
    <template #input>
      <PrismEditor v-if="showPrismEditor" class="prt-code-editor" v-model="state" :highlight="highlighter" line-numbers></PrismEditor>
    </template>

    <template
            v-for="slot in $options.slots"
            #[slot]
    >
      <slot :name="slot"/>
    </template>
  </CFormGroup>
</template>

<script>
import CFormGroup from '@coreui/vue/src/components/form/CFormGroup'
import { inputProps as props } from '@coreui/vue/src/components/form/form-props'
import * as allFormMixins from '@coreui/vue/src/components/form/form-mixins'
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism-coy.css'; // import syntax highlighting styles

const mixins = Object.values(allFormMixins)

export default {
  name: 'CodeEditor',
  slots: [
    'prepend',
    'prepend-content',
    'append-content',
    'append',
    'label-after-input',
    'valid-feedback',
    'invalid-feedback',
    'description'
  ],
  inheritAttrs: false,
  components: { CFormGroup, PrismEditor },
  mixins,
  props: {
    ...props,
    language: {
      type: String,
      default: 'html'
    }
  },
  data () {
    return {
      state: this.value,
      syncTimeout: null,
      showPrismEditor: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.showPrismEditor = true
    }, 20) //fixes problem with line-numbers, so i move this code to the next ticks
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages[this.language], this.language); // languages.<insert language> to return html with markup
    },
  },
  watch: {
    state (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
<style>
/* required class */
.prt-code-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #ffffff;
  color: #333;
  border: 1px solid #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 200px;
}
/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
